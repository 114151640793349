
const planYourStoreQuiz = () => {

	var top_level = WP.plan_your_store_data,
			current_level = [];

	const planYourStore = document.getElementById('plan_your_store');

	if (!planYourStore) return false;

	function update_html(level_to_build) {

		if (!level_to_build) return false;

		var insert_html = `<div class="prompt">${level_to_build.prompt ? level_to_build.prompt : 'Make your choice'}</div>`;

		for (var i = 0; i < level_to_build.options.length; i++) {

			if (level_to_build.options[i].option_outcome == 'end') {
				var next_step = 0,
						href = level_to_build.options[i].outcome.post_name ? '?outcome=' + level_to_build.options[i].outcome.post_name : '?outcome=' + level_to_build.options[i].outcome;
			} else {
				var next_step = level_to_build.options[i].options.length,
						href = '#next';
			}

			insert_html += `<a href="${href}" id="fff" class="option ${level_to_build.options[i].option_class}" data-num-of-options="${level_to_build.options.length}" data-option="${i}" data-next-step="${next_step}">
												<span class="option-name">${level_to_build.options[i].option_name}</span>
											</a>`;

			current_level[i] = level_to_build.options[i];

		}

		planYourStore.innerHTML = insert_html;

		setTimeout(function () {
			planYourStore.classList.remove('in-transition');
		}, 100);

	}

	update_html(top_level);

	document.addEventListener('click', function (e) {

		if (e.target.dataset.nextStep && e.target.dataset.nextStep >= 1) {
			planYourStore.classList.add('in-transition');
			setTimeout(function () {
				update_html(current_level[e.target.dataset.option]);
			}, 200);
			e.preventDefault();
		}

		if (e.target && e.target.id == 'start-again') {
			update_html(top_level);
			e.preventDefault();
		}

	});

}

export default planYourStoreQuiz;