import gsap from 'gsap';
import { MorphSVGPlugin } from '../libs/MorphSVGPlugin';
gsap.registerPlugin(MorphSVGPlugin);

const animateDivider = () => {

  let divider = document.getElementsByClassName('first');

  if (!divider) return false;

  for ( let i = divider.length - 1; i >= 0; i-- ) {

    let first = divider[i];

    let tl = gsap.timeline({
      repeat: -1,
      delay: 0,
      repeatDelay: 0,
    });

    tl.to(first, {morphSVG: first.nextElementSibling, duration: 10, ease: 'power1.inOut', repeatDelay: 0, delay: 0})
      .to(first, {morphSVG: first, duration: 10, ease: 'power1.inOut', repeatDelay: 0, delay: 0});

  }
};

export default animateDivider;