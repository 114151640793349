import ScrollBooster from 'scrollbooster';

const carouselInsights = () => {

  let booster = document.getElementsByClassName('carousel-insights');

  if (!booster) return false;

  for (var i = booster.length - 1; i >= 0; i--) {

    let wrap = booster[i].querySelector('.scroll-booster-wrap'),
        range = booster[i].nextElementSibling;

    new ScrollBooster({
      viewport: wrap,
      scrollMode: 'transform',
      direction: 'horizontal',
      bounce: true,
      friction: 0.1,
      onPointerDown: function() {
        document.body.classList.add('pressure');
      },
      onPointerUp: function() {
        document.body.classList.remove('pressure');
      },
      onUpdate: (state, event) => {
        const w = wrap.firstElementChild.offsetWidth - wrap.offsetWidth;
        const p = state.position.x / w;
        range.value = p * 100;
      }
    });
    range.addEventListener('input', (e) => {
      const p = e.target.value / 100;
      const w = wrap.firstElementChild.offsetWidth - wrap.offsetWidth;
      wrap.scrollTo(w * p, 0);
    });
  }
};

export default carouselInsights;