import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const animateEnter = () => {

  const items = gsap.utils.toArray('[data-enter]');

  if (!items) return false;

  items.forEach((el) => {

    let set_delay = el.hasAttribute('data-delay') ? el.getAttribute('data-delay') : 0;

    if ( el.hasAttribute('data-grandparent-trigger') ) {
      var set_trigger = el.parentElement.parentElement;
    } else if ( el.hasAttribute('data-parent-trigger') ) {
      var set_trigger = el.parentElement;
    } else {
      var set_trigger = el;
    }

    setTimeout(function() {
      gsap.to(el, {
        scale: 1,
        opacity: 1,
        x: 0,
        y: 0,
        rotation: 0,
        ease: 'back.out',
        duration: 0.6,
        delay: set_delay,
        scrollTrigger: {
          trigger: set_trigger,
          start: 'top 70%',
          toggleActions: "play complete complete reverse",
          // markers: true,
        }
      });
    }, 400);

  });
}

export default animateEnter;
