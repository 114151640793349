import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const animateOvertrades = () => {

  let bars = gsap.utils.toArray('[data-overtrade-bar]'),
      numbers = gsap.utils.toArray('[data-overtrade-percentage]');

  if (!bars || !numbers) return false;

  bars.forEach((el) => {
    gsap.from(el, {
      width: 10,
      duration: 1.8,
      scrollTrigger: {
        trigger: el.parentElement.parentElement,
        start: 'top 70%',
        toggleActions: "play complete complete reverse",
        // markers: true,
      }
    });
  });

  numbers.forEach((el) => {
    gsap.from(el, {
      duration: 1.8,
      innerText: 0,
      roundProps: "innerText",
      scrollTrigger: {
        trigger: el.parentElement.parentElement.parentElement,
        start: 'top 70%',
        toggleActions: "play complete complete reverse",
        // markers: true,
      }
    });
  });

}

export default animateOvertrades;