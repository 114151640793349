import axios from 'axios';
import Bouncer from 'formbouncerjs';

var bouncer = new Bouncer('[data-validate]', {
  disableSubmit: true,
});

const processForm = (e) => {
  e.preventDefault();
  const form = e.target;
  const formData = new FormData(form);

  formData.append('action', 'process_form');
  formData.append('nonce', WP.nonce);
  formData.append('form_id', form.getAttribute('data-form-id'));

  for (var value of formData.values()) {
    console.log(value);
  }

  document.addEventListener('bouncerFormInvalid', function (event) {
    console.log(event.detail.errors);
  }, false);


  /** If validation passes, fire AJAX submission */
  document.addEventListener('bouncerFormValid', function (e) {

    axios({
      method: 'POST',
      url: WP.ajax,
      data: formData
    })
      .then(function (response) {
        console.log('data: ' + response.data);
        console.log('status: ' + response.status);
        form.innerHTML = '<div class="success-msg">' + form.getAttribute('data-success') + '</div>';
      },
        (error) => {
          console.log(error);
        });

  }, false);

};

const initProcessForm = () => {

  const forms = document.querySelectorAll('[data-process]');

  for (let i = 0; i < forms.length; i++) {
    forms[i].addEventListener('submit', processForm, false);
  }

};

export default initProcessForm;