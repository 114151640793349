import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const animateTweets = () => {

  const tweets = gsap.utils.toArray('.ctf-item');

  if (!tweets) return false;

  tweets.forEach((el) => {

    setTimeout(function() {
      gsap.to(el, {
        scale: 1,
        opacity: 1,
        ease: 'back.out',
        duration: 0.8,
        scrollTrigger: {
          trigger: '.ctf-tweets',
          start: 'top 75%',
          toggleActions: "play complete complete reverse",
        }
      });
    }, 400);

  });
}

export default animateTweets;
