import Swiper, {Autoplay, A11y } from 'swiper'
Swiper.use([Autoplay, A11y])

const testimonialsSwiper = () => {

  const swiper = document.querySelector('[data-testimonials-swiper]')

  if (!swiper) return false

  new Swiper('[data-testimonials-swiper]', {
    loop: true,
    autoplay: false,
    freemode: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    grabCursor: true,
    slidesPerView: 1.3,
    spaceBetween: 10,
    breakpoints: {
      782: {
        slidesPerView: 2,
      },
      1200: {
        spaceBetween: 200,
      },
    },
  })

}

export default testimonialsSwiper