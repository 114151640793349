import gsap from 'gsap';

const navigation = () => {

  let toggle_icon = document.getElementById('toggle-navigation'),
      menu = document.getElementById('site-navigation'),
      colour_array = [ '#FCB32E', '#6C156D', '#04B8C7', '#2BA661', '#B71936' ];

  if (!toggle_icon) return false;

  toggle_icon.addEventListener('click', function(e) {
    if ( document.body.classList.contains('reveal-navigation') ) {
      document.body.classList.remove('reveal-navigation');
    } else {
      document.body.classList.add('reveal-navigation');
      menu.style.background = colour_array[Math.floor(Math.random() * colour_array.length)];
    }
    e.preventDefault();
  });

  document.onkeydown = function(evt) {
    evt = evt || window.event;
    if ( evt.keyCode == 27 && document.body.classList.contains('reveal-navigation') ) {
      document.body.classList.remove('reveal-navigation');
    }
  };

}

export default navigation;






