import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const animateConsumers = () => {

  let numbers = gsap.utils.toArray('[data-consumer-percentage]');

  if (!numbers) return false;

  numbers.forEach((el) => {
    gsap.from(el, {
      duration: 3,
      innerText: 0,
      roundProps: "innerText",
      scrollTrigger: {
        trigger: el.parentElement.parentElement.parentElement,
        start: 'top 70%',
        toggleActions: "play complete complete reverse",
        // markers: true,
      }
    });
  });

}

export default animateConsumers;